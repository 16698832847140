<template>
  <vx-card
    :title="
      this.customerUpdateID ? 'Edit Customer Update' : 'Create Customer Update'
    "
  >
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
        >Back</vs-button
      >
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          multiple
          :filterable="true"
          :options="optionTerritory"
          v-model="selectedTerritory"
        />
      </div>
    </div>

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Sales</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          multiple
          :filterable="false"
          :options="optionPersonal"
          v-model="selectedPersonal"
          @search="
            (search, loading) => {
              onSearchPersonal(search, loading);
            }
          "
        />
      </div>
    </div>

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Customer</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          multiple
          :filterable="false"
          :options="optionCustomer"
          v-model="selectedCustomer"
          @search="
            (search, loading) => {
              onSearchCustomer(search, loading);
            }
          "
        />
      </div>
    </div>

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Valid From</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <datepicker
          name="date"
          :inline="false"
          v-model="validFrom"
          placeholder="Select Date"
          v-validate="'required'"
        ></datepicker>
        <span class="text-danger text-sm" v-show="errors.has('validFrom')">{{
          errors.first("validFrom")
        }}</span>
      </div>
    </div>

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Valid To</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <datepicker
          name="date"
          :inline="false"
          v-model="validTo"
          placeholder="Select Date"
          v-validate="'required'"
        ></datepicker>
        <span class="text-danger text-sm" v-show="errors.has('validTo')">{{
          errors.first("validTo")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Status</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <input type="radio" id="active" value="Active" v-model="status" />
        <label for="active">Active</label>

        <input type="radio" id="inactive" value="Inactive" v-model="status" />
        <label for="inactive">Inactive</label>
      </div>
    </div>

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleBack"
          >Back</vs-button
        >
      </div>
    </div>
  </vx-card>
</template>
<script>
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import _ from "lodash";

export default {
  components: {
    Datepicker,
    vSelect,
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/sfa/v1/customer-update",
        customerUpdateID: null,
        optionTerritory: [],
        selectedTerritory: [],
        optionPersonal: [],
        selectedPersonal: [],
        optionCustomer: [],
        selectedCustomer: [],
        validFrom: null,
        validTo: null,
        status: "Active",
      };
    },
    handleBack() {
      this.$router.push({
        name: "customer-update",
      });
    },
    handleSubmit() {
      let territoryIDs = [];
      let salesPersonalIDs = [];
      let customerIDs = [];

      this.selectedTerritory.forEach((element) => {
        territoryIDs.push(element.id);
      });

      this.selectedPersonal.forEach((element) => {
        salesPersonalIDs.push(element.personal_id);
      });

      this.selectedCustomer.forEach((element) => {
        customerIDs.push(element.id);
      });

      if (!this.validFrom || !this.validTo) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Valid from and valid to is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }

      let body = {
        territory_ids: territoryIDs,
        sales_personal_ids: salesPersonalIDs,
        customer_ids: customerIDs,
        valid_from: this.validFrom
          ? moment(this.validFrom).format("YYYY-MM-DD")
          : null,
        valid_to: this.validTo
          ? moment(this.validTo).format("YYYY-MM-DD")
          : null,
        status: this.status,
      };

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();

          let res = null;
          if (this.customerUpdateID) {
            res = this.$http.put(
              this.baseUrl + "/" + this.customerUpdateID + "/update",
              body
            );
          } else {
            res = this.$http.post(this.baseUrl + "/create", body);
          }

          res
            .then((resp) => {
              if (resp.code == 200) {
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });

                this.$vs.loading.close();
                setTimeout(this.handleBack(), 2000);
              } else {
                this.$vs.loading.close();
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        }
      });
    },

    getTerritory() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
              if (this.optionTerritory.length > 0) {
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = [];
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = [];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },

    onSearchPersonal(search, loading, index) {
      if (search.length && this.selectedTerritory) {
        loading(true);
        this.searchPersonal(loading, search, this, index);
      }
    },
    searchPersonal: _.debounce((loading, search, t, index) => {
      var territorryIDs = [];
      t.selectedTerritory.forEach((element) => {
        territorryIDs.push(element.id);
      });

      t.$http
        .get(t.baseUrl + "/sales", {
          params: {
            length: 10,
            search: search.trim(),
            territory_ids: territorryIDs,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              t.optionPersonal = resp.data.records;
            } else {
              t.optionPersonal = [];
            }
            loading(false);
          } else {
            t.optionPersonal = [];
            loading(false);
          }
        });
    }, 350),
    onSearchCustomer(search, loading) {
      if (search.length && this.selectedTerritory) {
        loading(true);
        this.searchCustomer(loading, search, this);
      }
    },
    searchCustomer: _.debounce((loading, search, t) => {
      var territorryIDs = [];
      t.selectedTerritory.forEach((element) => {
        territorryIDs.push(element.id);
      });

      var personalIDs = [];
      t.selectedPersonal.forEach((element) => {
        personalIDs.push(element.personal_id);
      });
      t.$http
        .get(t.baseUrl + "/customer", {
          params: {
            length: 10,
            search: search.trim(),
            territory_ids: territorryIDs,
            personal_ids: personalIDs,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              t.optionCustomer = resp.data.records;
            } else {
              t.optionCustomer = [];
            }
            loading(false);
          } else {
            t.optionCustomer = [];
            loading(false);
          }
        });
    }, 350),
    getCustomerUpdate() {
      if (this.customerUpdateID) {
        this.$vs.loading();
        this.$http
          .get(this.baseUrl + "/" + this.customerUpdateID + "/edit")
          .then((resp) => {
            if (resp.code == 200) {
              this.selectedTerritory = resp.data.territories
                ? resp.data.territories
                : [];
              this.selectedPersonal = resp.data.sales_personals
                ? resp.data.sales_personals
                : [];
              this.selectedCustomer = resp.data.customers
                ? resp.data.customers
                : [];
              this.status = resp.data.status;
              this.validFrom = new Date(resp.data.valid_from);
              this.validTo = new Date(resp.data.valid_to);
            }
            this.$vs.loading.close();
          });
      } else {
        this.optionPersonal = [];
      }
    },
  },
  beforeMount() {
    this.getTerritory();
  },
  mounted() {
    this.customerUpdateID = this.$route.params.id;
    this.getCustomerUpdate();
  },
  watch: {
    selectedTerritory(val) {
      let territoryIDs = [];

      this.selectedTerritory.forEach((element) => {
        territoryIDs.push(element.id);
      });

      var indexSelectedCustomerToRemove = [];
      var indexSelectedPersonalToRemove = [];

      this.selectedPersonal.forEach((val, indexPersonal) => {
        if (!territoryIDs.includes(val.territory_id)) {
          if (!indexSelectedPersonalToRemove.includes(indexPersonal)) {
            indexSelectedPersonalToRemove.push(indexPersonal);
          }
        }
      });

      this.selectedCustomer.forEach((c, indexCustomer) => {
        let territoryIDs = c.territory_ids.split(",");
        let found = 0;
        val.forEach((t) => {
          if (territoryIDs.includes(t.id.toString())) {
            found++;
          }
        });

        if (found == 0) {
          if (!indexSelectedCustomerToRemove.includes(indexCustomer)) {
            indexSelectedCustomerToRemove.push(indexCustomer);
          }
        }
      });

      indexSelectedPersonalToRemove.forEach((val) => {
        this.selectedPersonal.splice(val, 1);
      });
      indexSelectedCustomerToRemove.forEach((val) => {
        this.selectedCustomer.splice(val, 1);
      });
    },
    selectedPersonal(val) {
      var indexSelectedCustomerToRemove = [];
      this.selectedCustomer.forEach((c, indexCustomer) => {
        let personalIDs = c.sales_personal_ids.split(",");
        let found = 0;
        val.forEach((t) => {
          if (personalIDs.includes(t.personal_id.toString())) {
            found++;
          }
        });

        if (found == 0) {
          if (!indexSelectedCustomerToRemove.includes(indexCustomer)) {
            indexSelectedCustomerToRemove.push(indexCustomer);
          }
        }
      });
      indexSelectedCustomerToRemove.forEach((val) => {
        this.selectedCustomer.splice(val, 1);
      });
    },
    selectedCustomer(val) {
      // console.log(val);
    },
  },
};
</script>

<style>
.multiselect__input {
  z-index: 0;
}
.multiselect__content-wrapper {
  z-index: 10;
}
</style>

